import React, {useState, useEffect} from 'react'
import { DataForm } from '../../../../../libs/react-mpk/components'
import { rules, storage } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'

const { inputTypes } = DataForm
const skey = storage.keys.SPT_1771_Induk

const Akuntan = ({
    data,
    handleUpdate,
    getLabel,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    useEffect(async () => {
        setDefaultData(data)
        setReady(true)
    }, [])

    return ready && (
        <DataForm
            baseId="mod-submission-spt-1771-Akuntan"
            className="mpk-margin-C"
            style={{
                width: '100%',
                maxWidth: 800,
            }}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={[
                {
                    inputType: inputTypes.SELECT,
                    label: getLabel('FlgAudit', 'Induk'),
                    key: 'FlgAudit',
                    options:[
                        {label:'Tidak Diaudit', value: '0'},
                        {label:'Diaudit', value: '1'}
                    ]
                },
                {
                    inputType: inputTypes.SELECT,
                    label: getLabel('OpAkt', 'Induk'),
                    key: 'OpAkt',
                    options:props.envStore.env.spt.opiniAkuntan,
                    show: d => (Number(d.FlgAudit) === 1)
                },
                {
                    inputType: inputTypes.INPUT,
                    label: getLabel('NmKAP', 'Induk'),
                    key: 'NmKAP',
                    show: d => (Number(d.FlgAudit) === 1),
                    required: true,
                    validation: 'max:200'
                },
                 // DJP PERUBAHAN NPWP_16+nitku asalnya 15 saja 
                {
                    inputType: inputTypes.INPUT,
                    label: getLabel('NPWPKAP', 'Induk'),
                    key: 'NPWPKAP',
                    show: d => (Number(d.FlgAudit) === 1),
                    minLength: 15,
                    maxLength: 15,
                    validation: d => (d.NmKAP.length > 0 ? `required|min:15` : 'min:0'),
                    required: true
                },
                 // DJP PERUBAHAN NPWP_16+nitku
                {
                    inputType: inputTypes.INPUT,
                    label: getLabel('NmAP', 'Induk'),
                    key: 'NmAP',
                    validation: d => (rules.validateNamaNpwp(d, 'NPWPAP')),
                    show: d => (Number(d.FlgAudit) === 1),
                },
                 // DJP PERUBAHAN NPWP_16+nitku
                {
                    inputType: inputTypes.INPUT,
                    label: getLabel('NPWPAP', 'Induk'),
                    key: 'NPWPAP',
                    show: d => (Number(d.FlgAudit) === 1),
                    minLength: 15,
                    maxLength: 15,
                    validation: d => (d.NmAP.length > 0 ? `required|min:15` : 'min:0'),
                }
                 // DJP PERUBAHAN NPWP_16+nitku
            ]}
            onChange={(data, key, value) => {
                if(['NPWPKAP', 'NPWPAP'].indexOf(key) >= 0)
                    value = rules.npwp(value)
                
                if(key === 'FlgAudit'){
                    if(value === '0'){
                        data.NmKAP = ''
                        data.NPWPKAP = ''
                    }
                    data.OpAkt = value === '1' ? '1' : ''
                }
                
                return data
            }}
            onSubmit={async (__data, callback) => {
                let {
                    FlgAudit,
                    OpAkt,
                    NmKAP,
                    NPWPKAP,
                    NmAP,
                    NPWPAP,
                } = __data

                // DJP PERUBAHAN NPWP_16+nitku
                // if(NPWPKAP.length != 22 && NPWPKAP.length != 16 && NPWPKAP.length != 15){
                //     callback("NPWP Kantor Akutan Publik required, 15 / 16 / 22 Digit", true)
                // } else if(NPWPAP && NPWPAP != "" && NPWPAP.length != 22 && NPWPAP.length != 16 && NPWPAP.length != 15){
                //     callback("NPWP Akutan Publik required, 15 / 16 / 22 Digit", true)
                //     console.log(NPWPAP)
                // } else {
                    await handleUpdate({
                        FlgAudit,
                        OpAkt,
                        NmKAP,
                        NPWPKAP,
                        NmAP,
                        NPWPAP,
                    })
                    callback(t.translate('sentence.savedItem'), false)
                // }
                // DJP PERUBAHAN NPWP_16+nitku
            }}
            submitLabel={t.translate('column.save')}
        />
    )
}


export default inject('envStore')(observer(Akuntan))
