import { nullLiteral } from "babel-types";
import React, { useState, useEffect } from "react";
import { DataForm } from "../../../../libs/react-mpk/components";
import { storage, options, rules } from "../../../../services";
import { getKlu, parseFilename } from "../../Submission.service";
import { inject, observer } from "mobx-react";
import moment from "moment";
import t from "counterpart";
import { find } from "lodash";
import HeaderLampKhus2A from "./LampKhus2A/HeaderLampKhus2A";
import LampKhus6A from "./LampKhus6A";
import numeral from "numeral";
import LapKeu from "./LapKeu";

import service, {
  getByReferenceNo,
  getRawData,
  downloadBpe,
  cancel,
  supportGet,
  supportGetRawData,
  supportExportSpt,
  exportSpt,
  getValidate,
  getSupportValidate,
  getCheckStatus,
  ExportXlsx,
  postXmlValidate,
  postSupportXmlValidate,
} from "./../../Submission.service";

const { inputTypes } = DataForm;
const skey = storage.keys.SPT_1771_Profile;
const columns = {
  THN_PJK: {
    key: "tahunPajak",
    reverseValue: (d) => String(d),
  },
  KD_PEMBETULAN_KE: {
    key: "kodePembetulan",
    reverseValue: (d) => String(d),
  },
  NPWP: "npwp",
  NAMA_WP: "namaWP",
  TGL_PENGUKUHAN: "tglPengukuhan",
  ALAMAT: "alamat",
  KODE_POS: "kodePos",
  KD_TLP: "kodeTelepon",
  TELEPON: "telepon",
  KD_FAX: "kodeFax",
  FAX: "fax",
  JENIS_USAHA: "jenisUsaha",
  KLU: "KLU",
  BULAN_MULAI: "bulanMulai",
  THN_MULAI: "thnMulai",
  BULAN_SELESAI: "bulanSelesai",
  THN_SELESAI: "thnSelesai",
  NAMA_TTD: "namaTTD",
  NPWP_TTD: "npwpTTD",
  NPWP_KUASA: "npwpKuasa",
  NAMA_KUASA: "namaKuasa",
  NEG_DOM: "negDom",
};

const generate = async (user, env) => {
  let currentYear = String(Number(moment(env.timestamp).year()) - 1);
  let data = await storage.generate(skey, {
    THN_PJK: currentYear,
    KD_PEMBETULAN_KE: "0",
    NPWP: "",
    NAMA_WP: "",
    TGL_PENGUKUHAN: "",
    ALAMAT: "",
    KODE_POS: "",
    KD_TLP: "",
    TELEPON: "",
    KD_FAX: "",
    FAX: "",
    JENIS_USAHA: "",
    KLU: "",
    BULAN_MULAI: "01",
    THN_MULAI: String(currentYear).substr(2, 2),
    BULAN_SELESAI: "12",
    THN_SELESAI: String(currentYear).substr(2, 2),
    NAMA_TTD: "",
    NPWP_TTD: "",
    NPWP_KUASA: "",
    NAMA_KUASA: "",
    NEG_DOM: "",
    FlgTtdSPT: "1",
  });

  return data;
};

const Profile = ({ getLabel, submission, ...props }) => {
  const induk = storage.get(storage.keys.SPT_1771_Induk);
  const headerLapKeu = storage.get(storage.keys.SPT_1771_HeaderLapKeu);
  const [ready, setReady] = useState(false);
  const [months] = useState(options.getMonths());
  const [years, setYears] = useState(
    options.getYears(
      props.envStore.env.timestamp,
      props.envStore.env.spt.minYear
    )
  );
  const [pembetulans, setPembetulans] = useState(options.getPembetulans());
  const [tahunBuku] = useState(
    options.getTahunBuku(props.envStore.env.timestamp)
  );
  const [tahunBukuSelesai] = useState([
    ...tahunBuku,
    {
      label: String(Number(tahunBuku[0].label) + 1),
      value: String(Number(tahunBuku[0].value) + 1),
    },
  ]);
  const [defaultData, setDefaultData] = useState();
  const [klu, setKlu] = useState([]);

  const handleGetKlu = (keyword, byCode = false) => {
    return new Promise(async (resolve) => {
      try {
        let res = await getKlu(keyword, byCode);
        setKlu(res.data);
        resolve();
      } catch (error) {
        // toast.errorRequest(error)
        resolve();
      }
    });
  };

  const handleUpdateData = (data) => {
    data.THN_MULAI = String(data.THN_PJK).substr(2, 2);
    const startDate = moment(
      `${data.BULAN_MULAI || "01"}-20${data.THN_MULAI}`,
      "MM-YYYY"
    );

    // Tambahan Pak Steven
    var endDate = moment(startDate).add(11, "month");
    try {
      if(data.flagTahun){
        endDate = startDate
      } else {
        endDate = endDate
      }
    } catch(e){}
    window.cekadadata = data

    // data.BULAN_SELESAI = months[numeral(endDate.month()).format('0')].value
    data.THN_SELESAI = String(endDate.year()).substr(2, 2);
    storage.update(skey, data);
    return data;
  };

  useEffect(async () => {
    let __data = storage.get(skey);
    let defData = await handleUpdateData(__data);
    let meta = parseFilename(submission.fileName);

    defData.KD_PEMBETULAN_KE = String(Number(meta.pembetulan));
    defData.THN_PJK = meta.taxYear;
    defData.BULAN_MULAI = meta.periodBegin;
    defData.BULAN_SELESAI = meta.periodEnd;
    defData.NPWP = meta.npwp;

     // DJP PERUBAHAN NPWP_16+nitku
    // try {
    //   var x = JSON.parse(localStorage.getItem('submission-data'))
    //   defData.npwp16 = x.npwp16;
    //   defData.nitku = x.nitku;
    // } catch(e){}
     // DJP PERUBAHAN NPWP_16+nitku

    // PERUBAHAN SIMULASI DIKOMENT
    if (props.envStore.widget.active) {
      let npwp = String(submission.fileName).substr(0, 15);
      let org = find(props.envStore.widget.organizations, { npwp });

      defData.NPWP = npwp;
      if (org) {
        defData.NAMA_WP = org.name || defData.NAMA_WP;
        defData.ALAMAT = org.infoAddress || defData.ALAMAT;
        defData.KODE_POS = org.infoPostal || defData.KODE_POS;
      }
    } else if (!props.authStore.user.isSupport) {
      let { npwp, name, address } = props.temporaryStore.properties.npwp;
      defData.NPWP = npwp || defData.NPWP;
      defData.NAMA_WP = name || defData.NAMA_WP;
      defData.ALAMAT = address || defData.ALAMAT;
      // PERUBAHAN SIMULASI DIKOMENT
    }

    // PERUBAHAN SIMULASI
    try {
      let res = await getRawData(submission.fileName)
        defData.NAMA_WP = res.data.data.profile.namaWP
        defData.ALAMAT = res.data.data.profile.kontak.alamat
    } catch(e){}
    // PERUBAHAN SIMULASI

    // defData.FlgTtdSPT = String(induk.FlgTtdSPT)
    defData.ID_TEMPLATE = String(headerLapKeu.ID_TEMPLATE);
    console.log(defData, "ASDASD")
    storage.update(skey, defData);
    setDefaultData(defData);
    setReady(true);
  }, []);

  return (
    ready && (
      <DataForm
        baseId="mod-submission-spt1771-profile"
        className="mpk-margin-C"
        hintMessage={props.errorMessage}
        asDialog={false}
        defaultData={defaultData}
        style={{ maxWidth: 1024 }}
        definitions={[
          {
            inputType: inputTypes.SELECT,
            label: getLabel("THN_PJK", "Profile"),
            key: "THN_PJK",
            className: "mpk-padding-N padding-right",
            width: "50%",
            options: years,
            disabled: true, //submission.referenceNo
          },
          {
            inputType: inputTypes.SELECT,
            label: getLabel("KD_PEMBETULAN_KE", "Profile"),
            key: "KD_PEMBETULAN_KE",
            width: "50%",
            options: pembetulans,
            disabled: true, //submission.referenceNo
          },
          {
            inputType: inputTypes.DIVIDER,
            label: t.translate("column.biodata"),
          },
          {
            inputType: inputTypes.INPUT,
            label: getLabel("NPWP", "Profile"),
            key: "NPWP",
            required: true,
            disabled: true,
            // show: !props.envStore.widget.active
          },
           // DJP PERUBAHAN NPWP_16+nitku
          // {
          //   inputType: inputTypes.INPUT,
          //   label: getLabel("npwp16", "Profile"),
          //   key: "npwp16",
          //   disabled: true,
          //   validation: 'min:16|max:16',
          //   maxLength:16,
          //   width: "50%",
          //   // show: !props.envStore.widget.active
          // },
          // {
          //   inputType: inputTypes.INPUT,
          //   label: getLabel("nitku", "Profile"),
          //   className: 'mpk-padding-N padding-left',
          //   key: "nitku",
          //   disabled: true,
          //   validation: 'min:22|max:22',
          //   maxLength:6,
          //   width: "50%",
          //   // show: !props.envStore.widget.active
          // },
           // DJP PERUBAHAN NPWP_16+nitku
          // {
          //     inputType: inputTypes.SELECT,
          //     label: getLabel('NPWP', 'Profile'),
          //     key: 'NPWP',
          //     required: true,
          //     options: props.envStore.widget.organizations ? props.envStore.widget.organizations.map(d => ({
          //         label: d.npwp,
          //         value: d.npwp
          //     })) : [],
          //     disabled: submission.referenceNo,
          //     show: props.envStore.widget.active
          // },
          {
            inputType: inputTypes.INPUT,
            label: getLabel("NAMA_WP", "Profile"),
            key: "NAMA_WP",
            required: true,
            validation: (data) => rules.validateNamaNpwp(data, "NPWP"),
            disabled: false,
          },
          {
            inputType: inputTypes.DATE,
            label: getLabel("TGL_PENGUKUHAN", "Profile"),
            key: "TGL_PENGUKUHAN",
          },
          {
            inputType: inputTypes.DIVIDER,
            label: t.translate("column.addressAndContact"),
          },
          {
            inputType: inputTypes.INPUT,
            label: getLabel("ALAMAT", "Profile"),
            key: "ALAMAT",
            className: "mpk-padding-N padding-right",
            width: "70%",
            required: true,
          },
          {
            inputType: inputTypes.INPUT,
            label: getLabel("KODE_POS", "Profile"),
            key: "KODE_POS",
            width: "30%",
            required: true,
            validation: "digits:5",
            maxLength: 5,
          },
          {
            inputType: inputTypes.INPUT,
            label: getLabel("KD_TLP", "Profile"),
            key: "KD_TLP",
            className: "mpk-padding-N padding-right",
            width: "30%",
            maxLength: 4,
            minLength: 2,
            validation: "min:2|max:4",
            required: true,
          },
          {
            inputType: inputTypes.INPUT,
            label: getLabel("TELEPON", "Profile"),
            key: "TELEPON",
            width: "70%",
            minLength: 7,
            maxLength: 12,
            validataion: "min:7|max:12",
            required: true,
          },
          {
            inputType: inputTypes.INPUT,
            label: getLabel("KD_FAX", "Profile"),
            key: "KD_FAX",
            className: "mpk-padding-N padding-right",
            width: "30%",
            minLength: 2,
            maxLength: 4,
            validation: "min:2|max:4",
          },
          {
            inputType: inputTypes.INPUT,
            label: getLabel("FAX", "Profile"),
            key: "FAX",
            width: "70%",
            minLength: 7,
            maxLength: 12,
            validation: (d) => (d.FAX.length > 0 ? "min:7|max:12" : "min:0"),
          },
          {
            inputType: inputTypes.DIVIDER,
            label: t.translate("column.usahaDanPembukuan"),
          },
          {
            inputType: inputTypes.AUTOCOMPLETE,
            label: getLabel("JENIS_USAHA", "Profile"),
            key: "JENIS_USAHA",
            className: "mpk-padding-N padding-right",
            width: "80%",
            required: false,
            validation: "min:5",
            data: klu.map((d) => ({
              children: <div style={{ whiteSpace: "wrap" }}>{d.nama}</div>,
              value: d.nama,
            })),
          },
          {
            inputType: inputTypes.AUTOCOMPLETE,
            label: "KLU",
            key: "KLU",
            width: "20%",
            validation: "min:5|max:6",
            minLength: 5,
            maxLength: 6,
            required: true,
            data: klu.map((d) => ({
              children: <div style={{ whiteSpace: "wrap" }}>{d.kode}</div>,
              value: d.kode,
            })),
          },
          {
            inputType: inputTypes.SELECT,
            label: getLabel("ID_TEMPLATE", "HeaderLapKeu"),
            options: props.envStore.env.spt.templateLapKeu,
            key: "ID_TEMPLATE",
          },
          {
            inputType: inputTypes.SELECT,
            label: getLabel("BULAN_MULAI", "Profile"),
            key: "BULAN_MULAI",
            width: "50%",
            options: months, //.filter((d, i) => (i < 7)),
            disabled: true, //false
          },
          {
            inputType: inputTypes.SELECT,
            className: "mpk-padding-N padding-left",
            label: getLabel("THN_MULAI", "Profile"),
            key: "THN_MULAI",
            width: "50%",
            options: [
              {
                label: "2014",
                value: "14",
              },
              ...tahunBuku,
            ],
            disabled: true,
          },
          {
            inputType: inputTypes.SELECT,
            label: getLabel("BULAN_SELESAI", "Profile"),
            key: "BULAN_SELESAI",
            width: "50%",
            options: months,
            disabled: true,
          },
          {
            inputType: inputTypes.SELECT,
            className: "mpk-padding-N padding-left",
            label: getLabel("THN_SELESAI", "Profile"),
            key: "THN_SELESAI",
            width: "50%",
            options: tahunBukuSelesai,
            disabled: true,
          },
          {
            inputType: inputTypes.DIVIDER,
            label: t.translate("column.penandaTangan"),
            show: induk.FlgTtdSPT === "1" || induk.FlgTtdSPT === "2",
          },
          {
            inputType: inputTypes.SELECT,
            label: getLabel("FlgTtdSPT", "Induk"),
            key: "FlgTtdSPT",
            options: [
              { label: "WP", value: "1" },
              { label: "Kuasa", value: "2" },
            ],
          },
          {
            inputType: inputTypes.INPUT,
            label: getLabel("NPWP_TTD", "Profile"),
            key: "NPWP_TTD",
            show: (d) => d.FlgTtdSPT === "1",
            required: true,
          },
          {
            inputType: inputTypes.INPUT,
            label: getLabel("NAMA_TTD", "Profile"),
            key: "NAMA_TTD",
            show: (d) => d.FlgTtdSPT === "1",
            validation: (data) => rules.validateNamaNpwp(data, "NPWP_TTD"),
            required: true,
          },
          {
            inputType: inputTypes.INPUT,
            label: getLabel("NPWP_KUASA", "Profile"),
            key: "NPWP_KUASA",
            show: (d) => d.FlgTtdSPT === "2",
            required: true,
          },
          {
            inputType: inputTypes.INPUT,
            label: getLabel("NAMA_KUASA", "Profile"),
            key: "NAMA_KUASA",
            show: (d) => d.FlgTtdSPT === "2",
            validation: (data) => rules.validateNamaNpwp(data, "NPWP_KUASA"),
            required: true,
          },
          {
            inputType: inputTypes.INPUT,
            label: getLabel("NEG_DOM", "Profile"),
            key: "NEG_DOM",
          },
        ]}
        onBeforeChange={(key, value) => {
          if (key === "JENIS_USAHA" && value.length >= 3) handleGetKlu(value);
          if (key === "KLU" && value.length >= 2) handleGetKlu(value, true);
          else if (["NPWP", "NPWP_TTD", "NPWP_KUASA"].indexOf(key) >= 0)
            return rules.npwp(value);
          else if (
            ["KODE_POS", "KD_TLP", "TELEPON", "KD_FAX", "FAX"].indexOf(key) >= 0
          )
            return String(value).replace(/\D/, "");
          return value;
        }}
        onChange={(data, key) => {
          if (key === "JENIS_USAHA") {
            if (data.JENIS_USAHA.length > 0) {
              let sp = find(klu, { nama: data.JENIS_USAHA });
              if (data.KLU === "" && sp) {
                data.KLU = sp.kode;
              }
              if (!sp && data.KLU !== "") {
                data.KLU = "";
                data.JENIS_USAHA = "";
              }
            } else {
              data.KLU = "";
            }
          }

          if (key === "KLU") {
            if (data.KLU.length > 0) {
              let sp = find(klu, { kode: data.KLU });

              if (sp) {
                data.JENIS_USAHA = sp.nama;
              }
              if (!sp && data.JENIS_USAHA !== "") {
                data.KLU = "";
                data.JENIS_USAHA = "";
              }
            } else {
              data.JENIS_USAHA = "";
            }
          }

          if (props.envStore.widget.active && key === "NPWP") {
            let cert = find(props.envStore.widget.organizations, {
              npwp: data.NPWP,
            });
            let { name, infoAddress, infoPostal } = cert;
            data.NAMA_WP = name;
            data.ALAMAT = infoAddress;
            data.KODE_POS = infoPostal;
          }

          return handleUpdateData(data);
        }}
        onSubmit={async (data, callback) => {
          induk.FlgTtdSPT = data.FlgTtdSPT;
          if (headerLapKeu.ID_TEMPLATE !== data.ID_TEMPLATE) {
            headerLapKeu.ID_TEMPLATE = data.ID_TEMPLATE;
            storage.update(storage.keys.SPT_1771_HeaderLapKeu, headerLapKeu);
            props.setIdTemplate(data.ID_TEMPLATE);
            await LapKeu.generate(true);
          }
          storage.update(storage.keys.SPT_1771_Induk, induk);
          storage.update(skey, data);

          props.temporaryStore.setProperties("isBut", data.NEG_DOM.length > 0);

          await HeaderLampKhus2A.recon();
          await LampKhus6A.recon(data.NEG_DOM.length > 0);
          callback(t.translate("sentence.savedItem"), false);
          props.onNextTab();
        }}
        submitLabel={t.translate("column.next")}
      />
    )
  );
};

Profile.generate = generate;
Profile.columns = columns;

export default inject(
  "envStore",
  "temporaryStore",
  "authStore"
)(observer(Profile));
