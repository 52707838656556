import React, { useEffect, useState } from "react";
import { TableWrapper, TabsWrapper } from "../../libs/react-mpk/wrapper";
import { LoadingButton } from "../../libs/react-mpk/components";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import service, {
  parseFilename,
  getByReferenceNo,
  getRawData,
  downloadBpe,
  cancel,
  supportGet,
  supportGetRawData,
  supportExportSpt,
  exportSpt,
  getValidate,
  getSupportValidate,
  getCheckStatus,
  ExportXlsx,
  postXmlValidate,
  postSupportXmlValidate,
} from "./Submission.service";
// import { SPTForm } from './SPTForm'
// import { storage } from '../../services'
import { toast } from "../../libs/react-mpk/services";
import History from "./History";
import moment from "moment";
import BPE from "./BPE/BPE";
// import Commit from './SPTForm/Commit'
import * as Certificate from "../Certificate";
import {
  AppSizeListener,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  FontIcon,
  Grid,
  GridCell,
  Radio,
  Select,
  TextIconSpacing,
} from "react-md";
import Commit from "./SPTForm/Commit/Commit";
import * as storage from "../../services/storage.service";
import {
  flattenObject,
  mapColumns,
  mapListItemColumns,
} from "../../services/utils.service";
import Profile from "./SPTForm/SPT1771/Profile";
import Rekapitulasi from "./SPTForm/SPT1771/Rekapitulasi";
import Induk from "./SPTForm/SPT1771/Induk";
import LampI from "./SPTForm/SPT1771/LampI";
import LampII from "./SPTForm/SPT1771/LampII";
import {
  HeaderLampIII,
  DetailLampIIIPPh22,
  DetailLampIIIPPh23,
} from "./SPTForm/SPT1771/LampIII";
import {
  HeaderLampIV,
  DetailLampIVA,
  DetailLampIVB,
} from "./SPTForm/SPT1771/LampIV";
import {
  HeaderLampV,
  DetailLampVPemegangSaham,
  DetailLampVPengurus,
} from "./SPTForm/SPT1771/LampV";
import {
  HeaderLampVI,
  DetailLampVIPenyertaanModal,
  DetailLampVIPiutangModal,
  DetailLampVIUtangModal,
} from "./SPTForm/SPT1771/LampVI";
import {
  DetailLampKhus1A,
  HeaderLampKhus1A,
} from "./SPTForm/SPT1771/LampKhus1A";
import DetailLampKhus1A2 from "./SPTForm/SPT1771/LampKhus1A/DetailLampKhus1A2";
import HeaderLampKhus2A from "./SPTForm/SPT1771/LampKhus2A/HeaderLampKhus2A";
import LampKhus4A from "./SPTForm/SPT1771/LampKhus4A";
import {
  LampKhus3A1,
  LampKhus3A2I,
  LampKhus3A2II,
  LampKhus3AII,
  LampKhus3AI,
} from "./SPTForm/SPT1771/LampKhus3";
import {
  HeaderLampKhus5A,
  DetailLampKhus5A,
} from "./SPTForm/SPT1771/LampKhus5A";
import LampKhus6A from "./SPTForm/SPT1771/LampKhus6A";
import {
  HeaderLampKhus7A,
  DetailLampKhus7A,
} from "./SPTForm/SPT1771/LampKhus7A";
import {
  HeaderLapKeu,
  Neraca1,
  Neraca3,
  Neraca5,
  Neraca7,
  Neraca9,
  Neraca11,
  Neraca13,
  Neraca15,
  Labarugi1,
  Labarugi3,
  Labarugi5,
  Labarugi7,
  Labarugi9,
  Labarugi11,
  Labarugi13,
  Labarugi15,
  HubIstimewa1,
  HubIstimewa3,
  HubIstimewa5,
  HubIstimewa7,
  HubIstimewa9,
  HubIstimewa11,
  HubIstimewa13,
  HubIstimewa15,
} from "./SPTForm/SPT1771/LapKeu";
import HeaderSSP from "./SPTForm/SPT1771/SSP/HeaderSSP";
import DetailSSP from "./SPTForm/SPT1771/SSP/DetailSSP";
import CreateForm from "./SPTForm/CreateForm";
import ExportSummary from "./ExportSummary";
import ImportForm from "./ImportForm";
import LogImport from "./LogImport";
import LogExport from "./ListLogExport";
import axios from "axios";
import httpService from "../../libs/react-mpk/services/http.service";
import { toJS } from "mobx";
import { Switch } from "react-router-dom/cjs/react-router-dom.min";
import _ from "lodash";
import numeral from "numeral";

const baseId = "mod-submission-list";
const statusTypes = TableWrapper.Status.types;

const bpeService = {
  status: (statusCode) => {
    // statusCode = Number(statusCode);
    return statusCode === 0
      ? "0 - Nihil"
      : statusCode === 1
        ? "1 - Kurang Bayar"
        : statusCode === 2
          ? "2 - Lebih Bayar"
          : null;
    // return statusCode
  },
  amount: (amount, taxType) => {
    let isDolar = String(taxType).match(new RegExp("badan dolar", "ig"))
      ? true
      : false;
    let currency = isDolar ? "US $" : "Rp,";
    amount = Number(amount);
    let __amount = numeral(Math.abs(amount)).format(
      isDolar ? "0,0.0000" : "0,0.00"
    );
    let result =
      amount < 0
        ? "(" + currency + " " + __amount + ")"
        : currency + " " + __amount;
    return isDolar
      ? String(result)
      : String(result)
        .replace(/,/g, ";")
        .replace(/\./g, ",")
        .replace(/;/g, ".");
  },
  amountNull: (amount, taxType) => {
    let isDolar = String(taxType).match(new RegExp("badan dolar", "ig"))
      ? true
      : false;
    let currency = isDolar ? "Rp," : "Rp,";
    amount = Number(amount);
    let __amount = numeral(Math.abs(amount)).format(
      isDolar ? "0,0.0000" : "0,0.00"
    );
    let result =
      amount < 0
        ? "(" + currency + " " + __amount + ")"
        : currency + " " + __amount;
    return isDolar
      ? String(result)
      : String(result)
        .replace(/,/g, ";")
        .replace(/\./g, ",")
        .replace(/;/g, ".");
  },
  masa: (masa1, masa2) => {
    let nm1 = Number(masa1) < 10 ? `0${masa1}` : `${masa1}`;
    let nm2 = Number(masa2) < 10 ? `0${masa2}` : `${masa2}`;
    masa1 = t.translate(`months.${nm1}`);
    masa2 = t.translate(`months.${nm2}`);
    return `${masa1} - ${masa2}`;
  },
  parseTaxType: (taxType) => {
    return `${taxType}`; //"SPT "+String(String(taxType).split('[')[1]).split(']')[0];
  },
  parseTimeStamp: (timeStamp) => {
    if (timeStamp.includes("ICT")) {
      timeStamp = timeStamp.replace("ICT ", "");
      return moment(new Date(timeStamp)).format("DD/MM/YYYY hh:mm:ss");
    } else {
      return timeStamp;
    }
  },
};

const initSubmission = (item) => {
  let meta = parseFilename(item.fileName);
  return Object.assign(item, meta);
};

const List = ({ className = "", ...props }) => {
  const { widget } = props.envStore;
  const [showSPTForm, setShowSPTForm] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showExportSummary, setShowExportSummary] = useState(false);
  const [showBpe, setShowBpe] = useState(false);
  const [showCommit, setShowCommit] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isShowCertUpload, setIsShowCertUpload] = useState(false);
  const [selectedNpwp, setSelectedNpwp] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(
    widget.active &&
    (Number(widget.createSpt) === 1 ||
      (widget.xbrl && props.temporaryStore.properties.xbrlData))
  );
  const [showImportForm, setShowImportForm] = useState(false);
  const updateDataItem = async (item) => {
    try {
      let res = await getByReferenceNo(
        item.referenceNo,
        props.authStore.user.isSupport
      );
      let itemData = initSubmission(res.data);
      TableWrapper.updateDataItem(
        baseId,
        { referenceNo: item.referenceNo },
        itemData
      );
    } catch (error) {
      toast.errorRequest(error);
    }
  };

  const handleCheckStatus = async (item) => {
    try {
      let res = await getCheckStatus(
        item.referenceNo,
        props.authStore.user.isSupport
      );
      TableWrapper.updateDataItem(
        baseId,
        { fileName: item.fileName },
        initSubmission(res.data)
      );
      toast.success(t.translate("sentence.completeCheckStatus"));
    } catch (error) {
      toast.errorRequest(error);
    }
  };

  const parseStatus = (item) => {
    // if(item.history){
    if (item.audit.isValidate) {
      return {
        flag: statusTypes.ERROR,
        label: t.translate("column.validate"),
        onClick: async () => {
          try {
            let res = await (props.authStore.user.isSupport
              ? getSupportValidate(item.referenceNo)
              : getValidate(item.referenceNo));
            TableWrapper.updateDataItem(
              baseId,
              { fileName: item.fileName },
              initSubmission(res.data)
            );
            if (res.data.status === "VALID")
              toast.success(t.translate("sentence.successSPTValidation"));
            else if (res.data.status === "ERROR")
              toast.warning(t.translate("sentence.errorSPTValidation"));
            else toast.warning(t.translate("sentence.inProgressSPTValidation"));
          } catch (error) {
            toast.errorRequest(error);
          }
        },
      };
    } else if (item.errorValidation) {
      return {
        flag: statusTypes.ERROR,
        label: t.translate("column.errorSPT"),
      };
    } else if (item.isNtte) {
      return {
        flag: statusTypes.SUCCESS,
        label: t.translate("column.success"),
        onClick: () => {
          setShowBpe(true);
          setSelectedItem(item);
        },
      };
    } else if (item.audit.isResumable) {
      return item.history && item.history.isError
        ? {
          flag: statusTypes.ERROR,
          label: t.translate("column.failed"),
        }
        : {
          flag: statusTypes.PROGRESS,
          label: t.translate("column.ready"),
        };
    } else if (item.audit.checkStatus) {
      return {
        flag: statusTypes.PROGRESS,
        label: props.authStore.hasPermission(
          "GET:/submission/{referenceNo}/check,GET:/submission/support/{referenceNo}/check"
        )
          ? t.translate("column.check")
          : t.translate("column.inProcess"),
        onClick: props.authStore.hasPermission(
          "GET:/submission/{referenceNo}/check,GET:/submission/support/{referenceNo}/check"
        )
          ? () => handleCheckStatus(item)
          : null,
      };
    } else if (item.history) {
      const { error } = item.history;
      const { isError } = item.audit;
      const msg = getErrorMessage(item);
      // if(msg.includes("xml vs xsd tranmitter tidak match di file") && isError){
      if (msg.includes("SPT sudah mendapat BPE") && isError) {
        return {
          flag: statusTypes.ERROR,
          label: t.translate("column.failed"),
          onClick: async () => {
            try {
              let res = await (props.authStore.user.isSupport
                ? postSupportXmlValidate(item.referenceNo)
                : postXmlValidate(item.referenceNo));
              TableWrapper.updateDataItem(
                baseId,
                { fileName: item.fileName },
                initSubmission(res.data)
              );
              if (res.data.status === "VALID")
                toast.success(t.translate("sentence.successSPTValidation"));
              else if (res.data.status === "ERROR")
                toast.warning(t.translate("sentence.errorSPTValidation"));
              else
                toast.warning(t.translate("sentence.inProgressSPTValidation"));
            } catch (error) {
              toast.errorRequest(error);
            }
          },
        };
      } else {
        return {
          flag: isError ? statusTypes.ERROR : statusTypes.PROGRESS,
          error,
          label: isError
            ? t.translate("column.failed")
            : t.translate("column.inProcess"),
          onClick: props.authStore.hasPermission(
            "GET:/submission/{referenceNo}/check,GET:/submission/support/{referenceNo}/check"
          )
            ? () => handleCheckStatus(item)
            : null,
          // onClick: item.isNtte
          //   ? () => {
          //     setShowBpe(true)
          //     setSelectedItem(item)
          //   }//downloadBpe(item.referenceNo)
          //   : item.audit.checkStatus
          //     ? () => {
          //         updateDataItem(item)
          //       }
          //     : null
        };
      }
    } else {
      return {
        flag: statusTypes.IDLE,
        label: t.translate("column.draft"),
      };
    }

    // if(isError){
    //   result.error = error
    // }else if(item.djpResponse){
    //   result.label = item.djpResponse.message
    // }

    // } else return {
    //   flag: statusTypes.IDLE,
    //   error: null,
    //   label: t.translate('sentence.sptNoHistory'),
    //   onClick: () => {
    //     handleGetRawData(item.fileName)
    //   }
    // }
  };

  const handleGetRawData = async (item, callback = () => { }) => {
    try {
      let res = await (props.authStore.user.isSupport
        ? supportGetRawData(item.fileName)
        : getRawData(item.fileName));
      if (res.data) {
        let code = props.envStore.env.spt.code[item.sptCode];
        storage.clearAll();
        let {
          profile,
          rekapitulasi,
          induk,
          lampI,
          lampII,
          lampIII,
          lampIV,
          lampV,
          lampVI,
          lampKhusIA,
          lampKhusIIA,
          lampKhusIIIA,
          lampKhusIVA,
          lampKhusVA,
          lampKhusVIA,
          lampKhusVIIA,
          lapKeu,
          ssp,
        } = res.data.data;

        profile = flattenObject(profile);
        profile = mapColumns(profile, Profile.columns, true);
        await storage.generate(storage.keys.SPT_1771_Profile, profile);

        rekapitulasi = mapColumns(rekapitulasi, Rekapitulasi.columns, true);
        await storage.generate(
          storage.keys.SPT_1771_Rekapitulasi,
          rekapitulasi
        );

        induk = flattenObject(induk);
        induk = mapColumns(induk, Induk.columns, true);
        if (!induk.NPWPAP) induk.NPWPAP = "";
        if (!induk.NPWPKP) induk.NPWPKP = "";
        if (!induk.NPWPKKP) induk.NPWPKKP = "";
        await storage.generate(storage.keys.SPT_1771_Induk, induk);

        lampI = flattenObject(lampI);
        lampI = mapColumns(lampI, LampI.columns, true);
        await storage.generate(storage.keys.SPT_1771_LampI, lampI);

        lampII = flattenObject(lampII);
        lampII = mapColumns(lampII, LampII.columns, true);
        await storage.generate(storage.keys.SPT_1771_LampII, lampII);

        let headerLampIII = mapColumns(
          lampIII.header,
          HeaderLampIII.columns,
          true
        );
        let detailPPh22 = mapListItemColumns(
          lampIII.detailPPh22,
          DetailLampIIIPPh22.itemColumns,
          true
        );
        let detailPPh23 = mapListItemColumns(
          lampIII.detailPPh23,
          DetailLampIIIPPh23.itemColumns,
          true
        );
        await storage.generate(
          storage.keys.SPT_1771_HeaderLampIII,
          headerLampIII
        );
        await storage.generate(storage.keys.SPT_1771_DetailLampIIIPPh22, {
          list: detailPPh22,
        });
        await storage.generate(storage.keys.SPT_1771_DetailLampIIIPPh23, {
          list: detailPPh23,
        });

        let headerLampIV = flattenObject({
          ...lampIV.header,
          a: flattenObject(lampIV.header.a),
          b: flattenObject(lampIV.header.b),
        });
        headerLampIV = mapColumns(headerLampIV, HeaderLampIV.columns, true);
        let detailLamp4A = mapListItemColumns(
          lampIV.detail4A,
          DetailLampIVA.itemColumns,
          true
        );
        let detailLamp4B = mapListItemColumns(
          lampIV.detail4B,
          DetailLampIVB.itemColumns,
          true
        );
        await storage.generate(
          storage.keys.SPT_1771_HeaderLampIV,
          headerLampIV
        );
        await storage.generate(storage.keys.SPT_1771_DetailLampIVA, {
          list: detailLamp4A,
        });
        await storage.generate(storage.keys.SPT_1771_DetailLampIVB, {
          list: detailLamp4B,
        });

        let headerLampV = mapColumns(lampV.header, HeaderLampV.columns, true);
        let detail4A = mapListItemColumns(
          lampV.detail5A,
          DetailLampVPemegangSaham.itemColumns,
          true
        );
        let detail4B = mapListItemColumns(
          lampV.detail5B,
          DetailLampVPengurus.itemColumns,
          true
        );
        await storage.generate(storage.keys.SPT_1771_HeaderLampV, headerLampV);
        await storage.generate(storage.keys.SPT_1771_DetailLampVPemegangSaham, {
          list: detail4A,
        });
        await storage.generate(storage.keys.SPT_1771_DetailLampVPengurus, {
          list: detail4B,
        });

        let headerLampVI = mapColumns(
          lampVI.header,
          HeaderLampVI.columns,
          true
        );
        let detail6A = mapListItemColumns(
          lampVI.detail6A,
          DetailLampVIPenyertaanModal.itemColumns,
          true
        );
        let detail6B = mapListItemColumns(
          lampVI.detail6B,
          DetailLampVIUtangModal.itemColumns,
          true
        );
        let detail6C = mapListItemColumns(
          lampVI.detail6C,
          DetailLampVIPiutangModal.itemColumns,
          true
        );
        await storage.generate(
          storage.keys.SPT_1771_HeaderLampVI,
          headerLampVI
        );
        await storage.generate(
          storage.keys.SPT_1771_DetailLampVIPenyertaanModal,
          { list: detail6A }
        );
        await storage.generate(storage.keys.SPT_1771_DetailLampVIUtangModal, {
          list: detail6B,
        });
        await storage.generate(storage.keys.SPT_1771_DetailLampVIPiutangModal, {
          list: detail6C,
        });

        let headerLampKhusIA = mapColumns(
          lampKhusIA.header,
          HeaderLampKhus1A.columns,
          true
        );
        let detailKhus1A = mapListItemColumns(
          lampKhusIA.detailKhus1A,
          DetailLampKhus1A.itemColumns,
          true
        );
        let detailKhus1A2 = mapListItemColumns(
          lampKhusIA.detailKhus1A2,
          DetailLampKhus1A2.itemColumns,
          true
        );
        await storage.generate(
          storage.keys.SPT_1771_HeaderLampKhus1A,
          headerLampKhusIA
        );
        await storage.generate(storage.keys.SPT_1771_DetailLampKhus1A, {
          list: detailKhus1A,
        });
        await storage.generate(storage.keys.SPT_1771_DetailLampKhus1A2, {
          list: detailKhus1A2,
        });

        lampKhusIIA = mapColumns(lampKhusIIA, HeaderLampKhus2A.columns, true);
        await storage.generate(
          storage.keys.SPT_1771_HeaderLampKhus2A,
          lampKhusIIA
        );

        let aI = mapListItemColumns(
          lampKhusIIIA.aI,
          LampKhus3AI.itemColumns,
          true
        );
        let aII = mapListItemColumns(
          lampKhusIIIA.aII,
          LampKhus3AII.itemColumns,
          true
        );
        let a1 = mapColumns(lampKhusIIIA.a1, LampKhus3A1.columns, true);
        let a2I = mapListItemColumns(
          lampKhusIIIA.a2I,
          LampKhus3A2I.itemColumns,
          true
        );
        let a2II = mapListItemColumns(
          lampKhusIIIA.a2II,
          LampKhus3A2II.columns,
          true
        );
        await storage.generate(storage.keys.SPT_1771_LampKhus3A1, a1);
        await storage.generate(storage.keys.SPT_1771_LampKhus3AII, {
          list: aII,
        });
        await storage.generate(storage.keys.SPT_1771_LampKhus3AI, { list: aI });
        await storage.generate(storage.keys.SPT_1771_LampKhus3A2I, {
          list: a2I,
        });
        await storage.generate(storage.keys.SPT_1771_LampKhus3A2II, a2II[0]);

        lampKhusIVA = mapColumns(lampKhusIVA, LampKhus4A.columns, true);
        console.log(
          "🚀 ~ file: List.js:277 ~ handleGetRawData ~ lampKhusIVA:",
          lampKhusIVA
        );
        await storage.generate(storage.keys.SPT_1771_LampKhus4A, lampKhusIVA);

        let headerLampKhus5A = mapColumns(
          lampKhusVA,
          HeaderLampKhus5A.columns,
          true
        );
        let detailLampKhus5A = mapListItemColumns(
          lampKhusVA.detail,
          DetailLampKhus5A.itemColumns,
          true
        );
        await storage.generate(
          storage.keys.SPT_1771_HeaderLampKhus5A,
          headerLampKhus5A
        );
        await storage.generate(storage.keys.SPT_1771_DetailLampKhus5A, {
          list: detailLampKhus5A,
        });

        lampKhusVIA = mapColumns(lampKhusVIA, LampKhus6A.columns, true);
        await storage.generate(storage.keys.SPT_1771_LampKhus6A, lampKhusVIA);

        let headerLampKhus7A = mapColumns(
          lampKhusVIIA.header,
          HeaderLampKhus7A.columns,
          true
        );
        let detailLampKhus7A = mapListItemColumns(
          lampKhusVIIA.detail,
          DetailLampKhus7A.itemColumns,
          true
        );
        await storage.generate(
          storage.keys.SPT_1771_HeaderLampKhus7A,
          headerLampKhus7A
        );
        await storage.generate(storage.keys.SPT_1771_DetailLampKhus7A, {
          list: detailLampKhus7A,
        });

        let headerLapKeu = mapColumns(
          lapKeu.header,
          HeaderLapKeu.columns,
          true
        );
        let neraca1 = mapColumns(lapKeu.lap1.neraca, Neraca1.columns, true);
        let neraca3 = mapColumns(lapKeu.lap3.neraca, Neraca3.columns, true);
        let neraca5 = mapColumns(lapKeu.lap5.neraca, Neraca5.columns, true);
        let neraca7 = mapColumns(lapKeu.lap7.neraca, Neraca7.columns, true);
        let neraca9 = mapColumns(lapKeu.lap9.neraca, Neraca9.columns, true);
        let neraca11 = mapColumns(lapKeu.lap11.neraca, Neraca11.columns, true);
        let neraca13 = mapColumns(lapKeu.lap13.neraca, Neraca13.columns, true);
        let neraca15 = mapColumns(lapKeu.lap15.neraca, Neraca15.columns, true);
        let labarugi1 = mapColumns(
          lapKeu.lap1.labarugi,
          Labarugi1.columns,
          true
        );
        let labarugi3 = mapColumns(
          lapKeu.lap3.labarugi,
          Labarugi3.columns,
          true
        );
        let labarugi5 = mapColumns(
          lapKeu.lap5.labarugi,
          Labarugi5.columns,
          true
        );
        let labarugi7 = mapColumns(
          lapKeu.lap7.labarugi,
          Labarugi7.columns,
          true
        );
        let labarugi9 = mapColumns(
          lapKeu.lap9.labarugi,
          Labarugi9.columns,
          true
        );
        let labarugi11 = mapColumns(
          lapKeu.lap11.labarugi,
          Labarugi11.columns,
          true
        );
        let labarugi13 = mapColumns(
          lapKeu.lap13.labarugi,
          Labarugi13.columns,
          true
        );
        let labarugi15 = mapColumns(
          lapKeu.lap15.labarugi,
          Labarugi15.columns,
          true
        );
        let hubIstimewa1 = mapListItemColumns(
          lapKeu.lap1.hubIstimewa,
          HubIstimewa1.itemColumns,
          true
        );
        let hubIstimewa3 = mapListItemColumns(
          lapKeu.lap3.hubIstimewa,
          HubIstimewa3.itemColumns,
          true
        );
        let hubIstimewa5 = mapListItemColumns(
          lapKeu.lap5.hubIstimewa,
          HubIstimewa5.itemColumns,
          true
        );
        let hubIstimewa7 = mapListItemColumns(
          lapKeu.lap7.hubIstimewa,
          HubIstimewa7.itemColumns,
          true
        );
        let hubIstimewa9 = mapListItemColumns(
          lapKeu.lap9.hubIstimewa,
          HubIstimewa9.itemColumns,
          true
        );
        let hubIstimewa11 = mapListItemColumns(
          lapKeu.lap11.hubIstimewa,
          HubIstimewa11.itemColumns,
          true
        );
        let hubIstimewa13 = mapListItemColumns(
          lapKeu.lap13.hubIstimewa,
          HubIstimewa13.itemColumns,
          true
        );
        let hubIstimewa15 = mapListItemColumns(
          lapKeu.lap15.hubIstimewa,
          HubIstimewa15.itemColumns,
          true
        );
        await storage.generate(
          storage.keys.SPT_1771_HeaderLapKeu,
          headerLapKeu
        );
        await storage.generate(storage.keys.SPT_1771_Neraca1, neraca1);
        await storage.generate(storage.keys.SPT_1771_Neraca3, neraca3);
        await storage.generate(storage.keys.SPT_1771_Neraca5, neraca5);
        await storage.generate(storage.keys.SPT_1771_Neraca7, neraca7);
        await storage.generate(storage.keys.SPT_1771_Neraca9, neraca9);
        await storage.generate(storage.keys.SPT_1771_Neraca11, neraca11);
        await storage.generate(storage.keys.SPT_1771_Neraca13, neraca13);
        await storage.generate(storage.keys.SPT_1771_Neraca15, neraca15);
        await storage.generate(storage.keys.SPT_1771_Labarugi1, labarugi1);
        await storage.generate(storage.keys.SPT_1771_Labarugi3, labarugi3);
        await storage.generate(storage.keys.SPT_1771_Labarugi5, labarugi5);
        await storage.generate(storage.keys.SPT_1771_Labarugi7, labarugi7);
        await storage.generate(storage.keys.SPT_1771_Labarugi9, labarugi9);
        await storage.generate(storage.keys.SPT_1771_Labarugi11, labarugi11);
        await storage.generate(storage.keys.SPT_1771_Labarugi13, labarugi13);
        await storage.generate(storage.keys.SPT_1771_Labarugi15, labarugi15);
        await storage.generate(storage.keys.SPT_1771_HubIstimewa1, {
          list: hubIstimewa1,
        });
        await storage.generate(storage.keys.SPT_1771_HubIstimewa3, {
          list: hubIstimewa3,
        });
        await storage.generate(storage.keys.SPT_1771_HubIstimewa5, {
          list: hubIstimewa5,
        });
        await storage.generate(storage.keys.SPT_1771_HubIstimewa7, {
          list: hubIstimewa7,
        });
        await storage.generate(storage.keys.SPT_1771_HubIstimewa9, {
          list: hubIstimewa9,
        });
        await storage.generate(storage.keys.SPT_1771_HubIstimewa11, {
          list: hubIstimewa11,
        });
        await storage.generate(storage.keys.SPT_1771_HubIstimewa13, {
          list: hubIstimewa13,
        });
        await storage.generate(storage.keys.SPT_1771_HubIstimewa15, {
          list: hubIstimewa15,
        });

        let headerSSP = mapColumns(ssp.header, HeaderSSP.columns, true);
        let detailSSP = mapListItemColumns(
          ssp.detail,
          DetailSSP.itemColumns,
          true
        );
        await storage.generate(storage.keys.SPT_1771_HeaderSSP, headerSSP);
        await storage.generate(storage.keys.SPT_1771_DetailSSP, {
          list: detailSSP,
        });
      }
      if (
        item.errorValidation &&
        item.errorValidation.errors &&
        item.errorValidation.errors.length > 0
      ) {
        for (const idx in item.errorValidation.errors) {
          Object.keys(item.errorValidation.errors[idx]).forEach(function (
            key,
            index
          ) {
            if (item.errorValidation.errors[idx][key].length > 100) {
              item.errorValidation.errors[idx][key] =
                item.errorValidation.errors[idx][key].slice(0, 100);
            }
          });
        }
      }
      storage.set(storage.keys.SPT_SUBMISSION, item);
      props.temporaryStore.setProperties("sptErrorData", null);
      props.navigationStore.redirectTo(
        `/${props.envStore.widget.active
          ? "widget"
          : props.envStore.env.applicationType
        }/submission/spt-1771/profile`
      );
      callback();
    } catch (error) {
      console.log(error);
      toast.errorRequest(error);
    }
  };

  const handlePembetulan = async (item) => {
    setShowCreateForm(true);
    setSelectedItem(item);
  };

  const handleDownloadBPE = async (item) => {
    try {
      await downloadBpe(item.referenceNo);
    } catch (error) {
      toast.errorRequest(error);
    }
  };

  const handleSend = async (item) => {
    setShowCommit(true);
    setSelectedItem(item);
  };

  const handleCancel = (item) => {
    props.modalStore.showConfirm({
      title: t.translate("modules.submission.sptForm.confirmation.cancelTitle"),
      children: t.translate(
        "modules.submission.sptForm.confirmation.cancelMessage"
      ),
      onSubmit: async (callback) => {
        try {
          if (item) await cancel(item.fileName);
          TableWrapper.deleteDataItem(baseId, { _id: item._id });
          callback();
        } catch (error) {
          callback();
          toast.errorRequest(error);
        }
      },
    });
  };

  const getErrorMessage = (item) => {
    if (item.isNtte) {
      return t.translate("sentence.sptSuccess");
    } else if (item.audit.isValidate) {
      return t.translate("sentence.checkValidation");
    } else if (item.audit.checkStatus) {
      return t.translate("sentence.checkStatus");
    } else if (item.errorValidation) {
      return t.translate("sentence.errorSPTValidation");
    } else if (item.audit.isResumable) {
      return item.history && item.history.isError
        ? item.history.error
        : t.translate("sentence.sptNoHistory");
    } else if (item.history && item.history.isError) {
      return item.history.error || "-";
    } else {
      let message = item.djpResponse
        ? item.djpResponse.message
        : item.history
          ? item.audit.isError
            ? item.history.error
            : "-"
          : t.translate("modules.submission.history.empty");

      return message; //message.split('-').pop()
    }
  };

  const handleExport = async (item) => {
    try {
      await (props.authStore.user.isSupport
        ? supportExportSpt(item.fileName, "induk")
        : exportSpt(item.fileName, "induk"));
      toast.success(t.translate("sentence.downloadingFile"));
    } catch (error) {
      toast.errorRequest(error);
    }
  };

  const [dialogBulk, setDialogBulk] = useState(false);
  const [selectType, setSelectType] = useState(null);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [dataFetch, setDataFetch] = useState([]);
  const [reportBox, setReportBox] = useState();
  const [allReport, setAllReport] = useState(false);

  useEffect(() => {
    var reportType = [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
    ];
    var reportBox1 = [];
    reportType.map((d) => {
      reportBox1.push({
        type: d,
        checked: false,
      });
    });
    setReportBox(reportBox1);
  }, []);

  const handleReportBox = (e) => {
    setAllReport(e.target.checked);
    var newReportBox = [...reportBox];
    var value = parseInt(e.target.value);
    if (value == 0) {
      var checked = !newReportBox[0].checked;
      if (checked) {
        setAllReport(true);
      } else {
        setAllReport(false);
      }
      newReportBox.map((d) => {
        d.checked = checked;
      });
    } else {
      newReportBox[value].checked = !newReportBox[value].checked;
      setAllReport(false);
    }
    setReportBox(newReportBox);
  };

  return (
    <>
      <Dialog
        visible={dialogBulk}
        onRequestClose={() => {
          setDialogBulk(false);
        }}
        style={{
          width: 800,
          height: 700,
        }}
      >
        <DialogHeader>Export Bulk</DialogHeader>
        <DialogContent>
          <AppSizeListener>
            <Grid>
              <GridCell colSpan={12}>
                <Checkbox
                  onChange={(e) => {
                    handleReportBox(e);
                  }}
                  value={0}
                  checked={reportBox ? reportBox[0].checked : false}
                  label="Seluruh Laporan Per Filename (Folder)"
                ></Checkbox>
              </GridCell>
              <GridCell colSpan={6}>
                <Checkbox
                  onChange={(e) => {
                    handleReportBox(e);
                  }}
                  value={1}
                  checked={reportBox ? reportBox[1].checked : false}
                  label="1. Induk"
                ></Checkbox>
                <Checkbox
                  onChange={(e) => {
                    handleReportBox(e);
                  }}
                  value={2}
                  checked={reportBox ? reportBox[2].checked : false}
                  label="2. Penghasilan Neto"
                ></Checkbox>
                <Checkbox
                  onChange={(e) => {
                    handleReportBox(e);
                  }}
                  value={3}
                  checked={reportBox ? reportBox[3].checked : false}
                  label="3. Harga Pokok Penjualan"
                ></Checkbox>
                <Checkbox
                  onChange={(e) => {
                    handleReportBox(e);
                  }}
                  value={4}
                  checked={reportBox ? reportBox[4].checked : false}
                  label="4. Kredit Pajak Dalam Negeri"
                ></Checkbox>
                <Checkbox
                  onChange={(e) => {
                    handleReportBox(e);
                  }}
                  value={5}
                  checked={reportBox ? reportBox[5].checked : false}
                  label="5. PPh Final & Penghasilan Bukan Objek Pajak"
                ></Checkbox>
                <Checkbox
                  onChange={(e) => {
                    handleReportBox(e);
                  }}
                  value={6}
                  checked={reportBox ? reportBox[6].checked : false}
                  label="6. Pemegang Saham / Pengurus"
                ></Checkbox>
                <Checkbox
                  onChange={(e) => {
                    handleReportBox(e);
                  }}
                  value={7}
                  checked={reportBox ? reportBox[7].checked : false}
                  label="7. Penyertaan Modal, Utang dan Piutang"
                ></Checkbox>
                <Checkbox
                  onChange={(e) => {
                    handleReportBox(e);
                  }}
                  value={8}
                  checked={reportBox ? reportBox[8].checked : false}
                  label="8. Penyusutan dan Amortisasi Fiscal"
                ></Checkbox>
                <Checkbox
                  onChange={(e) => {
                    handleReportBox(e);
                  }}
                  value={9}
                  checked={reportBox ? reportBox[9].checked : false}
                  label="9. Perhitungan Kompensasi Kerugian Fiscal"
                ></Checkbox>
              </GridCell>
              <GridCell colSpan={6}>
                <Checkbox
                  onChange={(e) => {
                    handleReportBox(e);
                  }}
                  value={10}
                  checked={reportBox ? reportBox[10].checked : false}
                  label="10. Hubungan Istimewa"
                ></Checkbox>
                <Checkbox
                  onChange={(e) => {
                    handleReportBox(e);
                  }}
                  value={11}
                  checked={reportBox ? reportBox[11].checked : false}
                  label="11. Pernyataan Transaksi Dalam Hubungan Istimewa"
                ></Checkbox>
                <Checkbox
                  onChange={(e) => {
                    handleReportBox(e);
                  }}
                  value={12}
                  checked={reportBox ? reportBox[12].checked : false}
                  label="12. Daftar Transaksi"
                ></Checkbox>
                <Checkbox
                  onChange={(e) => {
                    handleReportBox(e);
                  }}
                  value={13}
                  checked={reportBox ? reportBox[13].checked : false}
                  label="13. Daftar Fasilitas Penanaman Modal"
                ></Checkbox>
                <Checkbox
                  onChange={(e) => {
                    handleReportBox(e);
                  }}
                  value={14}
                  checked={reportBox ? reportBox[14].checked : false}
                  label="14. Cabang Utama Perusahaan"
                ></Checkbox>
                <Checkbox
                  onChange={(e) => {
                    handleReportBox(e);
                  }}
                  value={15}
                  checked={reportBox ? reportBox[15].checked : false}
                  label="15. Perhitungan PPh Pasal 26 Ayat 4"
                ></Checkbox>
                <Checkbox
                  onChange={(e) => {
                    handleReportBox(e);
                  }}
                  value={16}
                  checked={reportBox ? reportBox[16].checked : false}
                  label="16. Kredit Pajak Luar Negeri"
                ></Checkbox>
                <Checkbox
                  onChange={(e) => {
                    handleReportBox(e);
                  }}
                  value={17}
                  checked={reportBox ? reportBox[17].checked : false}
                  label="17. Laporan Keuangan"
                ></Checkbox>
                <Checkbox
                  onChange={(e) => {
                    handleReportBox(e);
                  }}
                  value={18}
                  checked={reportBox ? reportBox[18].checked : false}
                  label="18. BPE"
                ></Checkbox>
              </GridCell>
            </Grid>
          </AppSizeListener>
        </DialogContent>
        <DialogFooter>
          <Button
            id="dialog-close"
            onClick={() => {
              setDialogBulk(false);
            }}
            style={{ marginRight: 5 }}
          >
            Close
          </Button>
          <Button
            disabled={loadingDownload}
            onClick={async () => {
              // Validasi
              var validate = false;
              var v_selected = JSON.parse(TableWrapper.getSelected(baseId));
              var zeroSelected = false;
              if (v_selected.length == 0) {
                zeroSelected = true;
              }
              if (zeroSelected) {
                toast.info(
                  `Validasi ! Mohon pilih data dengan status Ready atau BPE`
                );
              } else {
                var v_selectedData = [];
                var v_others = [];
                var v_bpe = [];
                var v_bpe_invalid = [];
                v_selected.map((d) => {
                  var item = dataFetch[d];
                  if (item.isNtte == true) {
                  } else {
                    v_bpe_invalid.push(item);
                  }
                  if (item.isNtte == true) {
                    v_selectedData.push(dataFetch[d].fileName);
                  } else if (
                    dataFetch[0] &&
                    item.audit &&
                    item.audit.isResumable
                  ) {
                    if (item.history && item.history.isError) {
                      v_others.push(dataFetch[0]);
                    } else {
                      v_selectedData.push(dataFetch[d].fileName);
                    }
                  } else {
                    v_others.push(dataFetch[0]);
                  }
                });
                if (v_others.length == 0) {
                  if (reportBox[18].checked && v_bpe_invalid.length != 0) {
                    toast.error(
                      `Export Gagal, hanya status BPE yang dapat diexport, bila memilih report BPE`
                    );
                  } else {
                    validate = true;
                  }
                } else {
                  toast.error(
                    `Export Gagal, hanya status Ready atau BPE yang dapat diexport`
                  );
                }
                if (validate) {
                  setLoadingDownload(true);
                  try {
                    setDialogBulk(false);
                    var selected = JSON.parse(TableWrapper.getSelected(baseId));
                    var selectedData = [];
                    selected.map((d) => {
                      var item = dataFetch[d];
                      if (item.isNtte == true) {
                        selectedData.push({
                          fileName: dataFetch[d].fileName,
                          referenceNo: dataFetch[d].history.referenceNo,
                        });
                      } else if (
                        dataFetch[d] &&
                        item.audit &&
                        item.audit.isResumable
                      ) {
                        if (item.history && item.history.isError) {
                        } else {
                          selectedData.push({
                            fileName: dataFetch[d].fileName,
                            referenceNo: dataFetch[d].history.referenceNo,
                          });
                        }
                      }
                    });
                    var x = toJS(props.authStore);
                    var sendDataToBackend = [];
                    selectedData.map((d) => {
                      reportBox.map((d2) => {
                        if (d2.checked == true) {
                          sendDataToBackend.push({
                            type: d2.type,
                            filename: d.fileName,
                            referenceNo: d.referenceNo,
                            allReport: allReport,
                          });
                        }
                      });
                    });
                    console.log(sendDataToBackend, "SEND TO BACKEND");
                    let export_data = await axios.post(
                      `/api/export`,
                      {
                        data: sendDataToBackend,
                      },
                      {
                        headers: {
                          authorization: `Bearer ${x.hasAccessToken}`,
                        },
                      }
                    );
                    toast.success(
                      "Export berhasil, silahkan cek log export untuk cek proses !"
                    );
                    setLoadingDownload(false);
                  } catch (e) {
                    toast.error(e.message);
                    setLoadingDownload(false);
                  }
                }
              }
            }}
            theme="primary"
            themeType="contained"
            id="dialog-close"
          >
            <TextIconSpacing icon={<FontIcon>check</FontIcon>}>
              Export
            </TextIconSpacing>
          </Button>
        </DialogFooter>
      </Dialog>
      <>
        <TableWrapper
          selectable={true}
          baseId={baseId}
          title={t.translate("modules.submission.title")}
          className={`${className} mpk-padding-N padding-left`}
          hintMessage={t.translate("modules.submission.hint.message")}
          hintIconClassName="mdi mdi-information"
          hintMore={t.translate("modules.submission.hint.more")}
          defaultData={[]}
          defaultSortBy="createdAt"
          defaultSort={TableWrapper.sorts.descending}
          defaultQuery={{ column: "fileName" }}
          sideHeader={
            <>
              {!props.envStore.widget.active &&
                !props.authStore.user.isSupport && (
                  <div>
                    <div className="mpk-paper mpk-margin-N margin-bottom mpk-padding-N padding-all">
                      {props.envStore.env.kbs.map((d) => (
                        <LoadingButton
                          theme="primary"
                          themeType="contained"
                          className="mpk-full full-width"
                          iconClassName="mdi mdi-file-document"
                          onClick={() => {
                            if (window.kbsWidget)
                              window.kbsWidget[d.type](d.code);
                          }}
                        >
                          {d.label}
                        </LoadingButton>
                      ))}
                    </div>
                    {/* <Certificate.Info /> */}
                  </div>
                )}
            </>
          }
          columns={[
            {
              label: t.translate("column.fileName"),
              key: "fileName",
              searchable: true,
              render: (item) => (
                <div
                  className={"mpk-link"}
                  onClick={() => {
                    handleGetRawData(item);
                  }}
                >
                  {item.fileName}
                </div>
              ),
            },
            {
              label: t.translate("column.channel"),
              key: "channel",
              searchable: true,
              render: (item) => <pre>{item.channel}</pre>,
              show: props.authStore.user.isSupport,
            },
            {
              label: "Status",
              key: "status",
              searchable: true,
              render: (item) => {
                let stat = parseStatus(item);
                return (
                  <div>
                    <TableWrapper.Status
                      type={stat.flag}
                      onClick={stat.onClick}
                    >
                      {stat.label}
                    </TableWrapper.Status>
                  </div>
                );
              },
            },
            {
              label: t.translate("column.message"),
              key: "message",
              searchable: false,
              render: (item) => (
                <div
                  className={`mpk-min-width-L mpk-max-width-L mpk-padding-N padding-top padding-bottom mpk-font size-S`}
                >
                  {getErrorMessage(item)}
                </div>
              ),
            },
            {
              label: "NPWP",
              key: "npwp",
              searchable: false,
              render: (item) => item.npwp,
            },
             // DJP PERUBAHAN NPWP_16+nitku
            // {
            //   label: "NPWP 16",
            //   key: "npwp16",
            //   searchable: false,
            //   render: (item) => item.npwp16,
            // },
            // {
            //   label: "NITKU",
            //   key: "nitku",
            //   searchable: false,
            //   render: (item) => item.nitku,
            // },
             // DJP PERUBAHAN NPWP_16+nitku
            {
              label: "Status Sertifikat",
              key: "noCert",
              searchable: true,
              definition: {
                options: ["Sudah Upload", "Belum Upload"],
                inputType: TableWrapper.inputTypes.SELECT,
              },
              render: (item) => {
                return (
                  <div>
                    <TableWrapper.Status
                      type={
                        item.noCert
                          ? statusTypes.ERROR
                          : statusTypes.SUCCESS
                      }
                    // onClick={stat.onClick}
                    >
                      {item.noCert ? "Belum Upload" : "Sudah Upload"}
                    </TableWrapper.Status>
                  </div>
                );
              },
            },
            {
              label: t.translate("column.masaPajak"),
              key: "masaPajak",
              searchable: false,
              render: (item) => `${item.periodBegin}/${item.periodEnd}`,
            },
            {
              label: t.translate("column.tahunPajak"),
              key: "tahunPajak",
              searchable: false,
              render: (item) => `${item.taxYear}`,
            },
            {
              label: t.translate("column.pembetulan"),
              key: "pembetulan",
              searchable: false,
              render: (item) => `${item.pembetulan}`,
            },
            {
              label: "NTPA",
              key: "ntpa",
              searchable: false,
              render: (item) => item.ntpa || "-",
            },
            {
              label: "Status SPT",
              key: "statusSPT",
              searchable: false,
              render: (item) => {
                try {
                  if (item.rawData) {
                    return bpeService.status(
                      item.rawData.data.induk.dd.flgSptPPh
                    );
                  } else {
                    return "-";
                  }
                } catch (e) {
                  return "-";
                }
              },
            },
            {
              label: "Jumlah",
              key: "jumlah",
              searchable: false,
              render: (item) => {
                try {
                  if (item.rawData) {
                    var taxCode =
                      item.ntte && item.ntte.jenispajak
                        ? item.ntte.jenispajak
                        : null;
                    if (taxCode) {
                      return bpeService.amount(
                        item.rawData.data.induk.dd.dD11,
                        taxCode
                      );
                    } else {
                      return bpeService.amountNull(
                        item.rawData.data.induk.dd.dD11,
                        taxCode
                      );
                    }
                  } else {
                    return "-";
                  }
                } catch (e) {
                  console.log(e);
                  return "-";
                }
              },
            },
            {
              label: t.translate("column.createdAt"),
              key: "createdAt",
              searchable: false,
              type: TableWrapper.dataTypes.DATE,
              render: (item) => moment(item.createdAt).format("lll"),
            },
            {
              label: t.translate("column.createdBy"),
              key: "createdBy",
              searchable: props.authStore.user.isSupport,
              render: (item) => item.createdBy,
            },
            {
              label: t.translate("column.companyId"),
              key: "companyId",
              searchable: true,
              render: (item) => <pre>{item.companyId}</pre>,
              show: props.authStore.user.isSupport,
            },
          ]}
          actions={[
            // {
            //   label: "Import",
            //   iconClassName: "mdi mdi-upload",
            //   onClick: async () => {
            //     setShowImportForm(true);
            //   },
            // },
            // {
            //   label: "Export Bulk",
            //   iconClassName: "mdi mdi-file-pdf",
            //   onClick: async () => {
            //     var reportType = [
            //       0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
            //       16, 17, 18,
            //     ];
            //     var reportBox1 = [];
            //     reportType.map((d) => {
            //       reportBox1.push({
            //         type: d,
            //         checked: false,
            //       });
            //     });
            //     setReportBox(reportBox1);
            //     setDialogBulk(true);
            //   },
            // },
            {
              label: "Export Excel",
              iconClassName: "mdi mdi-file-excel",
              onClick: async () => {
                setShowExportSummary(true);
              },
              // show: !props.authStore.user.isSupport
            },
            {
              label: t.translate("column.add"),
              iconClassName: "mdi mdi-plus",
              onClick: () => {
                /*
    let { submission } = props.temporaryStore.properties
    let { env, widget } = props.envStore
    if(submission){
      props.temporaryStore.setProperties('submission', null)
      storage.clearAll()
    }
    props.navigationStore.redirectTo(`/${widget.active ? 'widget' : env.applicationType}/submission/spt-1771/profile`)
    */
                setShowCreateForm(true);
              },
              show: !props.authStore.user.isSupport,
            },
          ]}
          itemActions={[
            {
              label: t.translate(
                "modules.submission.sptForm.commit.title"
              ),
              iconClassName: "mdi mdi-send",
              onClick: handleSend,
              // show: item => (!item.isNtte && (item.history ? item.history.isError : true))
              show: (item) => item.audit.isResumable,
            },
            {
              label: t.translate("column.edit"),
              iconClassName: "mdi mdi-pencil",
              onClick: (item) => handleGetRawData(item),
              show: (item) => item.audit.isEditable,
            },
            {
              label: t.translate("column.pembetulan"),
              iconClassName: "mdi mdi-pencil-box-multiple",
              onClick: handlePembetulan,
              show: (item) =>
                item.isNtte ||
                (item.djpResponse &&
                  item.djpResponse.message &&
                  item.djpResponse.message.match(
                    /((?=sudah).*(?=dilaporkan)).*/gi
                  )),
            },
            {
              label: t.translate("column.history"),
              iconClassName: "mdi mdi-history",
              onClick: (item) => {
                setSelectedItem(item);
                setShowHistory(true);
              },
              show: (item) => item.history && !item.isNtte,
            },
            {
              label: t.translate("column.delete"),
              iconClassName: "mdi mdi-delete",
              onClick: handleCancel,
              show: (item) => item.audit.isEditable,
            },
            {
              label: t.translate("column.updateSertel"),
              iconClassName: "mdi mdi-upload",
              onClick: (item) => {
                setSelectedNpwp(String(item.fileName).substr(0, 15));
                setIsShowCertUpload(true);
              },
              show: (item) => props.envStore.widget.active,
            },
            {
              label: t.translate("column.exportInduk"),
              iconClassName: "mdi mdi-file-pdf",
              onClick: handleExport,
              show: (item) =>
                item.isNtte ||
                item.audit.checkStatus ||
                item.audit.isResumable,
            },
            {
              label: "Log Export",
              iconClassName: "mdi mdi-view-list",
              show: (item) =>
                item.isNtte ||
                item.audit.checkStatus ||
                item.audit.isResumable,
              onClick: async (item) => {
                sessionStorage.setItem('returnPath', window.location.pathname)
                props.navigationStore.redirectTo(`/${props.envStore.widget.active ? 'widget' : 'internal'}/submissions-log-export-v2/${item.fileName}/${item.rawData.referenceNo}`)
                // setShowImportForm(true);
              },
            },
          ]}
          onFetchData={async (query) => {
            return new Promise(async (resolve, reject) => {
              try {
                if (query.column === "noCert") {
                  query.keyword =
                    query.keyword === "Belum Upload" ? true : false;
                }
                let res = await (props.authStore.user.isSupport
                  ? supportGet(query)
                  : service.get(query));
                res.data = res.data.map((d) => {
                  d = initSubmission(d);
                  return d;
                });

                try {
                  var getRaws = await Promise.all(
                    res.data.map(async (item, index) => {
                      return new Promise(async (resolve, reject) => {
                        try {
                          var fileName = item.fileName;
                          let getRaw = await (props.authStore.user
                            .isSupport
                            ? supportGetRawData(fileName)
                            : getRawData(fileName));
                          item.rawData = getRaw.data;
                          resolve(item);
                        } catch (e) {
                          console.log(e);
                          resolve();
                        }
                      });
                    })
                  );
                } catch (e) { 
                  console.log(e, "ASDSADSAD")
                }

                try {
                  setDataFetch(res.data);
                } catch (e) { }
                resolve(res);
              } catch (error) {
                reject(error);
              }
            });
            return service.get(query);
          }}
          useFilter={true}
          useCriteria={false}
          showCommandbar={true}
          showFilterPeriod={false}
          defaultSize={20}
          showActionColumn={true}
        />
      </>
      {/* <SPTForm 
        visible={showSPTForm}
        onRequestClose={() => setShowSPTForm(false)}
      /> */}
      <History
        visible={showHistory}
        onRequestClose={() => {
          setShowHistory(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
      />
      <BPE
        visible={showBpe}
        onRequestClose={() => {
          setShowBpe(false);
          setSelectedItem(null);
        }}
        submission={selectedItem}
      />
      <Commit
        submission={selectedItem}
        visible={showCommit}
        onSuccess={() => TableWrapper.reload(baseId)}
        onRequestClose={() => {
          setShowCommit(false);
        }}
        onError={(errorData) => console.log(errorData)}
      />
      <CreateForm
        setShowCreateForm={setShowCreateForm}
        visible={showCreateForm}
        onRequestClose={() => {
          setShowCreateForm(false);
          setSelectedItem(null);
          props.envStore.setWidget({ createSpt: 0 });
        }}
        item={selectedItem}
        onFinished={
          selectedItem
            ? (item, callback) => handleGetRawData(item, callback)
            : null
        }
      />
      <Certificate.Upload
        visible={isShowCertUpload}
        onRequestClose={() => {
          setIsShowCertUpload(false);
          setSelectedNpwp(null);
        }}
        npwp={selectedNpwp}
        onFinished={(cert) => {
          toast.success(
            t.translate("modules.certificate.upload.certificateUploaded")
          );
        }}
      />
      <ExportSummary
        loadingDownload={loadingDownload}
        setLoadingDownload={setLoadingDownload}
        visible={showExportSummary}
        onRequestClose={() => setShowExportSummary(false)}
      />
      <ImportForm
        visible={showImportForm}
        onRequestClose={() => {
          setShowImportForm(false);
        }}
        onError={(errorData) => {
          setShowImportForm(false);
        }}
        onBack={() => {
          setShowImportForm(false);
          props.navigationStore.redirectTo(
            `/${props.envStore.widget.active
              ? "widget"
              : props.envStore.env.applicationType
            }/submissions`
          );
          console.log("onRequestClose");
        }}
      />
    </>
  );
};

export default inject(
  "navigationStore",
  "envStore",
  "temporaryStore",
  "modalStore",
  "authStore"
)(observer(List));
