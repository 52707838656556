/**
 * Check if `obj` is an object.
 *
 * @param {Object} obj
 * @return {Boolean}
 * @api private
 */

function isObject(obj) {
  return obj !== null && typeof obj === 'object';
}

module.exports = isObject;
