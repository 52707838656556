import React, {useState, useEffect} from 'react'
import { DataForm } from '../../../../../libs/react-mpk/components'
import { rules, storage } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'

const { inputTypes } = DataForm
const skey = storage.keys.SPT_1771_Induk

const Akuntan = ({
    data,
    handleUpdate,
    getLabel,
    ...props
}) => {
    const [defaultData, setDefaultData] = useState()
    const [ready, setReady] = useState(false)

    useEffect(async () => {
        setDefaultData(data)
        setReady(true)
    }, [])

    return ready && (
        <DataForm
            baseId="mod-submission-spt-1771-Akuntan"
            className="mpk-margin-C"
            style={{
                width: '100%',
                maxWidth: 800,
            }}
            hintMessage={props.errorMessage}
            asDialog={false}
            defaultData={defaultData}
            definitions={[
                {
                    inputType: inputTypes.INPUT,
                    label: getLabel('NmKKP', 'Induk'),
                    key: 'NmKKP',
                    validation: d => (rules.validateNamaNpwp(d, 'NPWPKKP'))
                },
                 // DJP PERUBAHAN NPWP_16+nitku
                {
                    inputType: inputTypes.INPUT,
                    label: getLabel('NPWPKKP', 'Induk'),
                    key: 'NPWPKKP',
                    minLength: 15,
                    maxLength: 15,
                    validation: d => (d.NmKKP.length > 0 ? `required|min:15` : 'min:0')
                },
                 // DJP PERUBAHAN NPWP_16+nitku
                {
                    inputType: inputTypes.INPUT,
                    label: getLabel('NmKP', 'Induk'),
                    key: 'NmKP',
                    validation: d => (rules.validateNamaNpwp(d, 'NPWPKP'))
                },
                 // DJP PERUBAHAN NPWP_16+nitku
                {
                    inputType: inputTypes.INPUT,
                    label: getLabel('NPWPKP', 'Induk'),
                    key: 'NPWPKP',
                    minLength: 15,
                    maxLength: 15,
                    validation: d => (d.NmKP.length > 0 ? `required|min:15` : 'min:0')
                }
                 // DJP PERUBAHAN NPWP_16+nitku
            ]}
            onChange={(data, key, value) => {
                if(['NPWPKAP', 'NPWPAP'].indexOf(key) >= 0)
                    value = rules.npwp(value)
                
                return data
            }}
            onSubmit={async (__data, callback) => {
                let {
                    NmKKP,
                    NPWPKKP,
                    NmKP,
                    NPWPKP
                } = __data

                // DJP PERUBAHAN NPWP_16+nitku
                // if(NPWPKKP.length != 22 && NPWPKKP.length != 16 && NPWPKKP.length != 15){
                //     callback("NPWP Kantor Konsultan Publik required, 15 / 16 / 22 Digit", true)
                // } else if(NPWPKP && NPWPKP != "" && NPWPKP.length != 22 && NPWPKP.length != 16 && NPWPKP.length != 15){
                //     callback("NPWP Konsultan Publik required, 15 / 16 / 22 Digit", true)
                // } else {
                    await handleUpdate({
                        NmKKP,
                        NPWPKKP,
                        NmKP,
                        NPWPKP
                    })
                    callback(t.translate('sentence.savedItem'), false)
                // }
            }}
            submitLabel={t.translate('column.save')}
        />
    )
}


export default inject('envStore')(observer(Akuntan))
